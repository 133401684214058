<template>
  <b-overlay :show="importacion.loading">
    <template #overlay>
      <div class="text-center">
        <loading :message="processingMessage"></loading>
      </div>
    </template>

    <!-- Modal para mostrar los errores de validación del layout -->
    <b-modal
      id="modal-errors"
      scrollable
      title="Se encontraron errores en el archivo layout"
      hide-footer
      no-close-on-backdrop
    >
      <b-card
        v-for="item in errors"
        :key="item.id"
        :header="'Fila número: ' + `${item.row + 1}`"
        no-body
        class="mb-3"
      >
        <b-list-group flush>
          <b-list-group-item variant="warning">Columna: {{ item.attribute }}</b-list-group-item>
          <b-list-group-item v-for="desc in item.errors" :key="desc.id" variant="danger">
            {{ desc }}
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-modal>

    <form>
      <!-- Fecha -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Fecha
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-datepicker
          v-model="importacion.fecha"
          @change="setImportacion({ key: 'fecha', value: $event })"
          name="fecha"
          ></b-form-datepicker>
      </b-form-group>

      <!-- Descripción -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Descripción
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-input
          v-model="importacion.descripcion"
          @change="setImportacion({ key: 'descripcion', value: $event })"
          name="descripcion"
          ></b-form-input>
      </b-form-group>

      <!-- Tipo -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Tipo
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-select
          v-model="importacion.tipo"
          :options="options"
          @change="setImportacion({ key: 'tipo', value: $event })"
          name="tipo"
          ></b-form-select>
      </b-form-group>

      <!-- Fuente de financiamiento -->
      <b-form-group v-show="!showFuenteFinanciamiento">
        <template #label>
          <label class="small font-weight-bolder">
            Fuente de financiamiento:
            <b-badge
              v-if="Boolean(fuente_financiamiento.id)"
              @click="showFuenteFinanciamiento = true"
              class="my-cursor"
              v-b-tooltip.hover
              title="Click para seleccionar otra fuente de financiamiento"
            >
              {{ fuente_financiamiento.nombre }}
            </b-badge>
          </label>
        </template>
      </b-form-group>

      <!-- Tipo de Movimiento: Ampliación/Reducción -->
      <b-form-group v-show="showFuenteFinanciamiento">
        <!-- Label -->
        <template #label>
          <label class="small font-weight-bolder">
            Fuente de financiamiento
            <span class="text-danger">*</span>
          </label>
        </template>
        <!-- Select -->
        <x-select
          noload="true"
          dispatchPath="fuenteFinanciamientoModule/getAll"
          getterListPath="fuenteFinanciamientoModule/getList"
          :formatListWith="['id', 'nombre']"
          :value="fuente_financiamiento.id"
          @input="cambiarFuente($event)"
          defaultText="-- Selecciona una fuente de financiamiento --"
        ></x-select>
      </b-form-group>

      <!-- Archivo layout -->
      <b-form-group description="(sólo archivos .xlsx)">
        <template #label>
          <label class="small font-weight-bolder">
            Archivo layout
            <span class="text-danger">*</span>
          </label>
        </template>
        <!-- Form file -->
        <b-form-file
          v-model="file"
          :state="Boolean(file)"
          placeholder="Selecciona el archivo layout excel correspondiente..."
          drop-placeholder="Arrastra el archivo layout excel correspondiente aquí..."
        ></b-form-file>
      </b-form-group>

      <!-- Sobreescribir el archivo previamente cargado -->
      <b-form-group>
        <b-form-checkbox
          switch
          v-model="importacion.overwrite"
          @change="setImportacion({ key: 'overwrite', value: $event })"
          name="check-button-override"
        >
          Sobreescribir el archivo previamente cargado
        </b-form-checkbox>
      </b-form-group>

      <!-- Guardar el archivo en memoria -->
      <b-form-group>
        <b-form-checkbox
          switch
          v-model="importacion.save_file"
          @change="setImportacion({ key: 'save_file', value: $event })"
          name="check-button-override"
        >
          Guardar el archivo en memoria
        </b-form-checkbox>
      </b-form-group>

      <!-- Botón para mostrar los errores de validación -->
      <b-form-group v-if="errors.length > 0">
        <b-button variant="danger" @click="$bvModal.show('modal-errors')">
          Mostrar errores
        </b-button>
      </b-form-group>

      <!-- Submit -->
      <b-form-group class="d-flex justify-content-end">
        <b-button variant="success" :disabled="!isImportacionFormValid" @click="submit">
          Subir archivo
        </b-button>
      </b-form-group>
    </form>
  </b-overlay>
</template>

<script>
import planeacionMixin from '@/mixins/planeacionMixin'
import presupuestoFederalMixin from '@/mixins/presupuestoFederalMixin'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/EventBus'
import XSelect from '@/components/Shared/XSelect'

export default {
  name: 'Import',

  mixins: [planeacionMixin, presupuestoFederalMixin],

  components: {
    XSelect
  },

  async created () {
    if (this.fuente === '') {
      this.showFuenteFinanciamiento = true
      await this.$getPeriodoFiscalAndFuenteFinanciamiento(`${this.namespaces}`)
      return
    }
    await this.$getPeriodoFiscalAndFuenteFinanciamiento(`${this.namespaces}`, this.fuente)
  },

  props: {
    namespaces: {
      type: String,
      required: true
    },

    fuente: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      //
      // Archivo layout seleccionado
      file: null,

      processingMessage: null,

      showFuenteFinanciamiento: false,

      errors: [],

      options: [
        { value: 'A', text: 'Ampliación' },
        { value: 'R', text: 'Reducción' }
      ]
    }
  },

  computed: mapState({
    fuente_financiamiento (state) {
      if (!state[this.namespaces].fuente_financiamiento) return {}

      return state[this.namespaces].fuente_financiamiento
    },
    importacion (state) {
      return state[this.namespaces].importacion
    },
    isImportacionFormValid (state, getters) {
      return getters[`${this.namespaces}/isImportacionFormValid`]
    }
  }),

  watch: {
    file (newValue) {
      this.setImportacion({ key: 'file', value: newValue })
    }
  },

  methods: {
    ...mapMutations({
      setImportacion (commit, payload) {
        return commit(`${this.namespaces}/setImportacion`, payload)
      }
    }),

    async submit () {
      this.processingMessage = 'Procesando archivo, por favor espere...'

      const getval = await this.$store.dispatch(`${this.namespaces}/uploadLayout`, this.importacion)

      EventBus.$emit(getval.error ? 'alert' : 'toast', 'Importación de layout', getval.message, {
        status: getval.error ? 'danger' : 'success'
      })

      if (getval.error) {
        this.errors = getval.data ? getval.data : []

        if (this.errors.length === 0) {
          this.$notify(getval, 'Importación de layout')
          return
        }

        this.$bvModal.show('modal-errors')

        this.file = null

        return
      }

      const getValObtenerRecursos = await this.$submitObtenerRecursos()

      if (getValObtenerRecursos.error) return

      this.$store.commit(`${this.namespaces}/setImportacion`, { key: 'processed', value: true })
    },

    async cambiarFuente ($event) {
      const fuente = await this.$store.dispatch('fuenteFinanciamientoModule/getById', $event)
      this.$store.commit(`${this.namespaces}/setFuenteFinanciamiento`, fuente.data)
    }
  }
}
</script>

<style>
.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: 'Explorar';
}
</style>
