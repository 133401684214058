<template>
  <div>
    <b-overlay :show="recursos.loading">
      <template #overlay>
        <div class="text-center">
          <loading message="Procesando información, por favor espere..."></loading>
        </div>
      </template>
    </b-overlay>

    <b-modal
      v-model="showModalForCrearRecurso"
      title="Crear recurso"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <template #default="{cancel}">
        <crear :namespaces="namespaces" @on-created="recursoCreado()">
          <template #btn-cancel-place>
            <b-button variant="black" @click="cancel">
              Cancelar
            </b-button>
          </template>
        </crear>
      </template>
    </b-modal>

    <div v-if="!recursos.loading">
      <div class="mb-4 d-flex flex-flow-reverse">
        <div class="w-100 d-flex justify-space-between"></div>
        <check-authorization
          :requiresAuthorizations="[meta.autorize]"
          :show-alert="false"
          no-slots
          #default="{ authorized, message }"
        >
          <span v-b-tooltip.top :title="message">
            <button
              @click="$isAuthorized(authorized, autorizar)"
              class="btn btn-dark btn-sm mr-3 mb-2 mb-sm-0"
              :disabled="!authorized"
            >
              Autorizar
            </button>
          </span>
        </check-authorization>

        <check-authorization
          :requiresAuthorizations="[meta.create]"
          :show-alert="false"
          no-slots
          #default="{ authorized, message }"
        >
          <span v-b-tooltip.top :title="message">
            <button
              @click="$isAuthorized(authorized, crearRecurso)"
              class="btn btn-success btn-sm"
              style="white-space: nowrap"
              :disabled="!authorized"
            >
              Crear recurso
            </button>
          </span>
        </check-authorization>
      </div>
      <b-table
        :fields="fields"
        :items="items"
        no-border-collapse
        responsive
        striped
        bordered
        sticky-header
        head-variant="light"
        head-row-variant="light"
        show-empty
        style="min-height: 50vh; max-height: 500px;"
        class="my-table"
      >
        <template #cell(unidad_administrativa)="data">
          <div v-if="data.item.id === editingItem.id" style="min-width: 200px;">
            <x-select
              v-model="editingItem.unidad_administrativa_id"
              dispatchPath="administrativeUnitModule/getAdministrativeUnits"
              getterListPath="administrativeUnitModule/getAdministrativeUnits"
              :formatListWith="['id', 'nombre']"
              no-validate
            >
              <template #first>
                <b-form-select-option :value="null">
                  -- Selecciona un proyecto --
                </b-form-select-option>
              </template>
            </x-select>
          </div>
          <div
            v-else
            class="d-flex align-items-center text-center overflow-auto bg-blue rounded p-2"
            style="min-height: 80px"
          >
            <strong class="text-white text-center w-100" style="font-size: 0.65rem">
              {{ data.item.unidad_administrativa_nombre }}
            </strong>
          </div>
        </template>
        <template #cell(proyecto)="data" style="color: red">
          <div v-if="data.item.id === editingItem.id" style="width: 200px;">
            <x-select
              v-model="editingItem.proyecto_id"
              dispatchPath="proyectoModule/getProyectos"
              getterListPath="proyectoModule/getAllProyectos"
              :formatListWith="['id', 'nombre']"
              no-validate
            >
              <template #first>
                <b-form-select-option :value="null">
                  -- Selecciona un proyecto --
                </b-form-select-option>
              </template>
            </x-select>
          </div>
          <div
            v-else
            class="d-flex align-items-center my-auto text-center"
            style="max-height: 80px"
          >
            <b-badge class="w-100">{{ data.item.proyecto_clave }}</b-badge>
          </div>
        </template>
        <template #cell(partida)="data">
          <div v-if="data.item.id === editingItem.id" style="width: 200px;">
            <x-select
              v-model="editingItem.partida_id"
              dispatchPath="partidaModule/getAll"
              getterListPath="partidaModule/getPartidas"
              :formatListWith="['id', 'clave_nombre']"
              no-validate
            >
              <template #first>
                <b-form-select-option :value="null">
                  -- Selecciona un proyecto --
                </b-form-select-option>
              </template>
            </x-select>
          </div>
          <div v-else>
            <b class="text-info">{{ data.item.partida_clave }}</b>
            <br />
            {{ data.item.partida_nombre }}
          </div>
        </template>
        <template #cell(total)="data">
          <b-badge variant="danger">
            {{ $formatNumber(data.value.importe) }}
          </b-badge>
        </template>
        <template #cell()="data">
          <b-form-input
            v-if="data.item.id === editingItem.id"
            v-model="editingItem[getKeyName(data)]"
            class="text-center"
            :style="getKeyName(data) === 'rubro' ? 'width: 200px' : 'width: 100px'"
          ></b-form-input>
          <div
            v-else
            class="d-flex align-items-center justify-content-center my-auto p-0 m-0 rounded text-center"
            style="min-height: 80px;"
          >
            <span v-if="typeof data.value === 'string'">
              {{ data.value }}
            </span>
            <b-badge v-else variant="dark">
              {{ $formatNumber(data.value) }}
            </b-badge>
          </div>
        </template>
        <template #cell(acciones)="row">
          <div class="d-flex flex-column align-items-center" style="font-size: 1rem">
            <div v-if="row.item.id === editingItem.id" class="d-flex">
              <b-button
                v-if="isEditing"
                class="mr-2 mb-2 mb-sm-0"
                size="sm"
                @click="toggleEditMode(null)"
                variant="dark"
              >
                Cancelar
              </b-button>
              <b-button
                v-if="isEditing"
                class="btn-outline-success mr-2 mb-2 mb-sm-0"
                size="sm"
                variant="white"
                @click="update"
              >
                Guardar
              </b-button>
            </div>
            <u-d-controls
              v-else
              :editPath="`${namespaces}/update`"
              :deletePath="`${namespaces}/delete`"
              :id="row.item.id"
              :editPermissions="[meta.update]"
              :deletePermissions="[meta.delete]"
              hide-edit-action
            >
              <template #edit>
                <b-button
                  v-if="row.item.id !== editingItem.id"
                  class="btn-outline-primary mr-2 mb-2 mb-sm-0"
                  size="sm"
                  variant="white"
                  @click="toggleEditMode(row.item)"
                >
                  Editar
                </b-button>
              </template>
            </u-d-controls>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import presupuestoFederalMixin from '@/mixins/presupuestoFederalMixin'
import planeacionMixin from '@/mixins/planeacionMixin'
import Crear from '@/components/FederalYAmpliacion/Crear'
import _ from 'lodash'

export default {
  name: 'Edicion',

  mixins: [planeacionMixin, presupuestoFederalMixin],

  components: {
    Crear
  },

  props: {
    namespaces: {
      type: String,
      required: true
    },

    fuente: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      fields: [],

      items: null,

      loading: false,

      meta: null,

      editingItem: {},

      showModalForCrearRecurso: false
    }
  },

  async created () {
    const meta = this.$router.currentRoute.meta.permissions
    this.meta = meta

    const fuente = this.$store.state[this.namespaces].fuente_financiamiento

    if (fuente === null) {
      await this.$getPeriodoFiscalAndFuenteFinanciamiento(`${this.namespaces}`, 'fuente_federal')
    } else {
      await this.$store.dispatch('fuenteFinanciamientoModule/getByName', {
        key: 'clave',
        value: fuente.clave
      })
      await this.$getCurrentPeriodoFiscal(`${this.namespaces}`)
    }

    this.getData()
  },

  computed: {
    periodo_fiscal () {
      return this.$store.state[this.namespaces].periodo_fiscal
    },

    registros () {
      return this.$store.state[this.namespaces].registros
    },
    recursos () {
      return this.$store.state[this.namespaces].recursos
    },
    isEditing () {
      return !_.isEmpty(this.editingItem)
    }
  },

  watch: {
    async registros (newVal) {
      this.fields = this.registros[0]
      this.items = this.registros.slice(1, this.registros.length)
    }
  },

  methods: {
    crearRecurso () {
      this.showModalForCrearRecurso = true
    },

    async toggleEditMode (item = null) {
      if (!item) {
        this.editingItem = {}
        await this.getData()
        return
      }

      this.editingItem = item
    },

    async update () {
      const getval = await this.$store.dispatch(`${this.namespaces}/update`, this.editingItem)

      if (getval.error) {
        this.$notify(getval, 'Importacion de layout')
        return
      }
      this.toggleEditMode()
    },

    async getData () {
      await this.$submitObtenerRecursos()

      this.fields = this.registros[0]
      this.items = this.registros.slice(1, this.registros.length)
    },

    getKeyName (data) {
      return data.field.key
    },

    recursoCreado () {
      this.showModalForCrearRecurso = false
      this.getData()
    },

    async autorizar () {
      const getval = await this.$store.dispatch(`${this.namespaces}/autorize`, this.periodo_fiscal)

      if (getval.error) {
        this.$notify(getval, 'Importacion de layout')
        return
      }

      this.$notify(getval, 'Autorización')
    }
  }
}
</script>

<style lang="scss" scoped>
.vgt-row-header {
  color: blue;
  font-size: 0.75rem;
}

/deep/ .table > tbody > tr {
  text-align: center;
}

.my-table {
  /deep/ .table > thead > tr > th {
    font-size: 0.9em;
    text-align: center;
  }

  /deep/ .table > tbody > tr > td {
    font-size: 0.9em;
    vertical-align: middle;
  }

  /deep/ .table > tbody > tr > th {
    vertical-align: middle;
  }
}
</style>
